import React from 'react'
import { navigate } from 'gatsby'
import { Head } from '@components'
import * as st from '@assets/styl/404.module.styl'
import { useLanguage } from '../hooks'
import { translatePath } from '../translations'

const Terms = () => {
  const { t } = useLanguage()

  return (
    <>
      <Head pathname={translatePath('/sucesso/')} title="Dimas Construções" />
      <section className={st.core}>
        <div>
          <h1>{t('PageSucess.title')}</h1>
          <p>
            {t('PageSucess.paragraph.1')}
            <br />
            {t('PageSucess.paragraph.2')}
          </p>
          <button onClick={() => navigate(-1)} className="btn">
            {t('PageSucess.button')}
          </button>
        </div>
      </section>
    </>
  )
}

export default Terms
